import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueI18n from 'vue-i18n';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBold,
  faCompressArrowsAlt,
  faDollarSign,
  faEnvelope,
  faExpandArrowsAlt,
  faFont,
  faItalic,
  faLock,
  faPowerOff,
  faRedo,
  faSortDown,
  faTextHeight,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { localeService } from '@/services/LocaleService';
import { pl } from '@/translations/pl';
import { en } from '@/translations/en';

Vue.config.productionTip = false;

[
  faLock,
  faEnvelope,
  faPowerOff,
  faSortDown,
  faBold,
  faCompressArrowsAlt,
  faExpandArrowsAlt,
  faFont,
  faItalic,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faTextHeight,
  faUndo,
  faRedo,
  faAlignJustify,
  faDollarSign,
].map((icon: any) => {
  library.add(icon);
});

Vue.component('font-awesome-icon', FontAwesomeIcon);

(window as any).Vue = Vue;

const i18n = new VueI18n({
  locale: localeService.getLocale(),
  fallbackLocale: 'pl',
  messages: { pl, en },
});

(window as any).VueGlobal = new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app');
