import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '../views/Login.vue';
import { tokenService } from '@/services/TokenService';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/spreadsheet-editor/:id',
    name: 'SpreadsheetEditor',
    component: () => import(/* webpackChunkName: "spreadsheetEditor" */ '../views/SpreadsheetEditor.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/company-structure-group',
    name: 'CompanyStructureGroup',
    component: () => import(/* webpackChunkName: "companyStructureGroup" */ '../views/CompanyStructureGroup.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/company-structure-group/:type',
    name: 'CompanyStructureGroupType',
    component: () => import(/* webpackChunkName: "companyStructureGroup" */ '../views/CompanyStructureGroup.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/company-structure-unit',
    name: 'CompanyStructureUnit',
    component: () => import(/* webpackChunkName: "companyStructureUnit" */ '../views/CompanyStructureUnit.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/company-structure-unit/:type',
    name: 'CompanyStructureUnitType',
    component: () => import(/* webpackChunkName: "companyStructureUnit" */ '../views/CompanyStructureUnit.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/data-sources',
    name: 'DataSources',
    component: () => import(/* webpackChunkName: "dataSources" */ '../views/DataSources.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/data-sources/:type',
    name: 'DataSourcesType',
    component: () => import(/* webpackChunkName: "dataSources" */ '../views/DataSources.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/financial-categories',
    name: 'FinancialCategories',
    component: () => import(/* webpackChunkName: "financialCategories" */ '../views/FinancialCategories.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/financial-categories/:type',
    name: 'FinancialCategoriesType',
    component: () => import(/* webpackChunkName: "financialCategories" */ '../views/FinancialCategories.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: () => import(/* webpackChunkName: "myAccount" */ '../views/MyAccount.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/my-account/:type',
    name: 'MyAccountType',
    component: () => import(/* webpackChunkName: "myAccount" */ '../views/MyAccount.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/parameters',
    name: 'Parameters',
    component: () => import(/* webpackChunkName: "parameters" */ '../views/Parameters.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/parameters/:type',
    name: 'ParametersType',
    component: () => import(/* webpackChunkName: "parameters" */ '../views/Parameters.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/reports/:type',
    name: 'ReportsType',
    component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/report/:id/:start/:end/:group',
    name: 'Report',
    component: () => import(/* webpackChunkName: "report" */ '../views/Report.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/reports-configuration',
    name: 'ReportsConfiguration',
    component: () => import(/* webpackChunkName: "reportsConfiguration" */ '../views/ReportsConfiguration.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/reports-configuration/:type',
    name: 'ReportsConfigurationType',
    component: () => import(/* webpackChunkName: "reportsConfiguration" */ '../views/ReportsConfiguration.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/structures-configuration',
    name: 'StructuresConfiguration',
    component: () => import(/* webpackChunkName: "structuresConfiguration" */ '../views/StructuresConfiguration.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/structures-configuration/:type',
    name: 'StructuresConfigurationType',
    component: () => import(/* webpackChunkName: "structuresConfiguration" */ '../views/StructuresConfiguration.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/structure-configuration-params/:id/:type',
    name: 'StructureConfigurationParamsType',
    component: () =>
      import(/* webpackChunkName: "structureConfigurationParams" */ '../views/StructureConfigurationParams.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/structure-configuration-params/:id',
    name: 'StructureConfigurationParams',
    component: () =>
      import(/* webpackChunkName: "structureConfigurationParams" */ '../views/StructureConfigurationParams.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/reports-configuration-fields/:id/:type',
    name: 'ReportsConfigurationFieldsType',
    component: () =>
      import(/* webpackChunkName: "reportsConfigurationFields" */ '../views/ReportsConfigurationFields.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/reports-configuration-fields/:id',
    name: 'ReportsConfigurationFields',
    component: () =>
      import(/* webpackChunkName: "reportsConfigurationFields" */ '../views/ReportsConfigurationFields.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/search/:type',
    name: 'SearchType',
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/manual-data',
    name: 'ManualData',
    component: () => import(/* webpackChunkName: "manualData" */ '../views/ManualData.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/manual-data/:type',
    name: 'ManualDataType',
    component: () => import(/* webpackChunkName: "manualData" */ '../views/ManualData.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/search-config',
    name: 'SearchConfig',
    component: () => import(/* webpackChunkName: "searchConfig" */ '../views/SearchConfig.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/search-config/:type',
    name: 'SearchConfigType',
    component: () => import(/* webpackChunkName: "searchConfig" */ '../views/SearchConfig.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/spreadsheets',
    name: 'Spreadsheets',
    component: () => import(/* webpackChunkName: "spreadsheets" */ '../views/Spreadsheets.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/spreadsheets/:type',
    name: 'SpreadsheetsType',
    component: () => import(/* webpackChunkName: "spreadsheets" */ '../views/Spreadsheets.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/custom-queries',
    name: 'CustomQueries',
    component: () => import(/* webpackChunkName: "customQueries" */ '../views/CustomQueries.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/custom-query-test/:type',
    name: 'CustomQueryTest',
    component: () => import(/* webpackChunkName: "customQueryTest" */ '../views/CustomQueryTest.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/custom-queries/:type',
    name: 'CustomQueriesType',
    component: () => import(/* webpackChunkName: "customQueries" */ '../views/CustomQueries.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/user-management',
    name: 'UserManagement',
    component: () => import(/* webpackChunkName: "userManagement" */ '../views/UserManagement.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/user-management/:type',
    name: 'UserManagementType',
    component: () => import(/* webpackChunkName: "userManagement" */ '../views/UserManagement.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'),
    meta: {},
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!tokenService.getToken()) {
      next({
        path: '/',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
