
























import { Component, Vue } from 'vue-property-decorator';
import { authService } from '@/services/AuthService';
import { tokenService } from '@/services/TokenService';

@Component({})
export default class Login extends Vue {
  isSubmitted = false;
  email = '';
  password = '';
  isLoading = false;
  isLoginError = false;
  errorMsg = 'Incorrect credentials';
  isLoadingPage = true;

  login() {
    this.errorMsg = 'Server error - please try again later';
    this.isSubmitted = true;
    authService
      .login(this.email, this.password)
      .then((data: any) => {
        tokenService.saveToken(data.token);
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect as string).catch(() => {});
        } else {
          this.$router.push('/dashboard').catch(() => {});
        }
      })
      .catch((error: any) => {
        if (error.data && error.data.error) {
          this.errorMsg = error.data.error;
        }
        this.isLoginError = true;
      });
  }

  mounted() {
    if (tokenService.getToken()) {
      this.$router.push('/dashboard').catch(() => {
        this.isLoadingPage = false;
      });
    } else {
      this.isLoadingPage = false;
    }
  }
}
